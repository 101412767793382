@tailwind base;
@tailwind components;
@tailwind utilities;
  
@font-face {
    font-family: "Heebo Bold";
    font-weight:700;
    src: url("../src/assets/Fonts/Heebo-Bold.ttf");
}
@font-face {
    font-family: "Heebo Medium";
    font-weight:400;
    src: url("../src/assets/Fonts/Heebo-Medium.ttf");
}
@font-face {
    font-family: "Heebo";
    src: url("../src/assets/Fonts/Heebo-Regular.ttf");
}
@font-face {
    font-family: "Ubuntu Medium";
    font-weight:400;
    src: url("../src/assets/Fonts/Ubuntu-Medium.ttf");
}
@font-face {
    font-family: "Ubuntu Bold";
    font-weight:700;
    src: url("../src/assets/Fonts/Ubuntu-Bold.ttf");
}

.customSlider-track {
    @apply bg-gray-300 rounded top-1 h-[7px] px-2;
}

.customSlider-track.customSlider-track-0 {
    @apply bg-[#082996]
}
.customSlider-thumb {
    @apply  border-white border-2 bg-[#082996] shadow-md w-[25px] h-[25px]  mt-[-3.5px] rounded-full outline-none cursor-pointer;
}

.customSlider-thumb:hover {
    box-shadow: 0 0 0 8px rgb(104, 117, 217, 0.2);
}

.overlay{
    @apply bg-[rgba(0,0,0,0.6)] z-[100] h-[100vh] w-[100vw] absolute top-0
}